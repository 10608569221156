'use strict'

import {pictures, places, sityInfroStruct} from "./yMapPlaces";

const mapHelper = {
  createPicturesCollection(map) {
    for (const i in pictures) {
      map.geoObjects.add(new ymaps.Placemark(pictures[i].coordinates, {
        hintContent: pictures[i].name,
        balloonContent: pictures[i].balloonContent || null,
      }, {
        iconLayout: 'default#image',
        iconImageHref: pictures[i].icon,
        iconImageSize: pictures[i].sizes,
        iconImageOffset: pictures[i].offset,
      }));
    }
  },

  createPlacesColletion(onClick = null) {
    let collection = new ymaps.GeoObjectCollection({}, {
      iconLayout: 'default#image',
      iconImageSize: places.sizes,
    });
    for (const i in places.items) {
      const p = new ymaps.Placemark(
        places.items[i].coordinates, {
          hintContent: places.items[i].name,
          balloonContent: places.items[i].name,
        }, {
          iconImageHref: places.items[i].icon,
          iconImageOffset: places.items[i].offset,
          iconImageSize: places.items[i].sizes,
        },
      );

      if (onClick)
        onClick(p, i);

      collection.add(p);
    }

    return collection;
  },
  createGeoObjectsByType(type) {
    let collection = new ymaps.GeoObjectCollection({}, {
      iconLayout: 'default#image',
      iconImageHref: sityInfroStruct[type].icon,
      iconImageSize: sityInfroStruct[type].sizes,
      iconImageOffset: sityInfroStruct[type].offset,
    });

    for (let j in sityInfroStruct[type].items) {
      collection.add(new ymaps.Placemark(sityInfroStruct[type].items[j].coordinates, {
        hintContent: sityInfroStruct[type].items[j].name,
        balloonContent: sityInfroStruct[type].items[j].name,
      }, {}))
    }
    return collection;
  }
}

export {pictures, places, sityInfroStruct, mapHelper};
// module.exports = mapHelper;
