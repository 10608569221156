'use strict';

import {places, pictures, sityInfroStruct, mapHelper} from './yMap/yMapHelper';

let accordionToggle = document.querySelectorAll('.js-accordion-toggle');

var myMap = null;
let geoObjectsCollections = {};
let placesCollection = {};

var locationMap = null;
let geoObjectsLocationCollections = {};
let placesLocationCollection = {};

function initmap (ymaps) {
  // environment map
  myMap = new ymaps.Map("YMapsID", {
    center: pictures.skyhouse.coordinates,
    zoom: 14,
    controls: ['zoomControl']
  });

  mapHelper.createPicturesCollection(myMap);

  placesCollection = mapHelper.createPlacesColletion(function (p, i) {
      p.events.add('click', function () {
        clickOnMapPlace(places.items[i].key);
      });
  });
  myMap.geoObjects.add(placesCollection);

  for (const i in sityInfroStruct) {
    geoObjectsCollections[i] = mapHelper.createGeoObjectsByType(i);
    myMap.geoObjects.add(geoObjectsCollections[i]);
  }


  // location map
  locationMap = new ymaps.Map("YMapsIDLocation", {
    center: pictures.skyhouse.coordinates,
    zoom: 14,
    controls: ['zoomControl']
  });

  mapHelper.createPicturesCollection(locationMap);

  placesLocationCollection = mapHelper.createPlacesColletion();
  locationMap.geoObjects.add(placesLocationCollection);

  for (const i in sityInfroStruct) {
    geoObjectsLocationCollections[i] = mapHelper.createGeoObjectsByType(i);
    locationMap.geoObjects.add(geoObjectsLocationCollections[i]);
  }
}

function clickOnMapPlace(place) {
  let domElem = document.getElementById(place+'-menu-item');
  accordionToggle.forEach((el) => {
    if (el.classList.contains('_expanded')) {
      el.classList.remove('_expanded');
    }
  });
  if (!domElem.classList.contains('_expanded')) {
    domElem.click();
  }
}

function toggleMarkers(collectionName) {
  if (sityInfroStruct[collectionName].enabled) {
    geoObjectsCollections[collectionName].removeAll();
    sityInfroStruct[collectionName].enabled = false;

  } else {
    geoObjectsCollections[collectionName] = mapHelper.createGeoObjectsByType(collectionName);
    myMap.geoObjects.add(geoObjectsCollections[collectionName]);
    sityInfroStruct[collectionName].enabled = true;
  }
}

function clickOnInfroStructItem(e) {
  e.preventDefault();
}

document.addEventListener('DOMContentLoaded', () => {
  ymaps.ready(initmap);

  const toggleMarkerButtons = Array.prototype.slice.call(document.getElementsByClassName('ymap-toggle-collection'));
  toggleMarkerButtons.forEach(tmb => tmb.addEventListener('click', function(e) {
    const collectionName = this.dataset.collectionName;
    toggleMarkers(collectionName);
  }));

  const infrostructItems = Array.prototype.slice.call(document.getElementsByClassName('infrastructure-group__link'));
  infrostructItems.forEach(isi => isi.addEventListener('click', clickOnInfroStructItem))
});
