'use strict';

const places = {
  sizes: [25, 25],
  items: [
    {key: 'muzeon', name: 'Парк Музеон / Крымская набережная', icon: '/images/map-icons/places/one.svg', coordinates: [55.734160, 37.606566], sizes: [25, 25], offset: [-12.5, -25]},
    {key: 'shuhovskii', name: 'Шуховская башня', icon: '/images/map-icons/places/three.svg', coordinates: [55.717310, 37.611615], sizes: [25, 25], offset: [-30, -25]},
    {key: 'donskoi', name: 'Донской монастырь', icon: '/images/map-icons/places/four.svg', coordinates: [55.714460, 37.601258], sizes: [25, 25], offset: [-30, -25]},
    {key: 'neskuchnii', name: 'Нескучный сад', icon: '/images/map-icons/places/two.svg', coordinates: [55.717246, 37.587342], sizes: [25, 25], offset: [-30, -25]},
  ],
  enabled: true
}

const pictures = {
  gorkii : {name: 'Центральный парк культурыи отдыха им. М.Горькогo', icon: '/images/map-icons/places/gorkii.svg', coordinates: [55.729949, 37.601735], sizes: [56, 20], offset: [-23, -20]},
  shuhovski : {name: 'Шуховская башня', icon: '/images/map-icons/places/shuhovski-tower.svg', coordinates: [55.717310, 37.611615], sizes: [18, 60], offset: [-9, -60]},
  donskoi : {name: 'Донской монастырь', icon: '/images/map-icons/places/donskoi.svg', coordinates: [55.714460, 37.601258], sizes: [38, 42], offset: [-8, -42]},
  neskuchnii : {name: 'Нескучный сад', icon: '/images/map-icons/places/neskuchnii-garden.svg', coordinates: [55.717246, 37.587342], sizes: [32, 42], offset: [-8, -42]},
  skyhouse : {name: 'SkyHouse', balloonContent: "Sky House адрес точки ул.Мытная, вл. 40-44", icon: '/images/map-icons/places/skyhouse.svg', coordinates: [55.722203, 37.615349], sizes: [46, 42], offset: [-23, -42]},
};

const sityInfroStruct = {
  kindergarden: {
    icon: "/images/map-icons/sity-infrostructure/kindergarden.svg",
    sizes: [23, 42],
    offset: [-11.5, -42],
    items: [
      {name: "Sun school",  coordinates: [55.719084, 37.616332]},
      {name: "Нескучный садик", coordinates: [55.722036, 37.602085]},
    ],
    enabled: true
  },

  // школы
  school: {
    icon: "/images/map-icons/sity-infrostructure/education.svg",
    sizes: [24, 42],
    offset: [-12, -42],
    items: [
      {name: "Высшая Школа Экономики РЭУ им. Плеханова", coordinates: [55.722261, 37.612228]},
      {name: "РЭУ им. Плеханова", coordinates: [55.727298, 37.627696]},
      {name: "РГУ им. Косыгина", coordinates: [55.719700, 37.600500]},
      {name: "МИСиС", coordinates: [55.728638, 37.610633]},
    ],
    enabled: true
  },

  // больницы
  hospital: {
    icon: "/images/map-icons/sity-infrostructure/hospital.svg",
    sizes: [21, 42],
    offset: [-10.5, -42],
    items: [
      {name: "Поликлиника No2 им. Семашко", coordinates: [55.730267, 37.618988], },
      {name: "Морозовская детская больница", coordinates: [55.724046, 37.618745]},
      {name: "Первая Градская больница", coordinates: [55.724320, 37.602315]},
      {name: "Педиатры Онлайн", coordinates: [55.720307, 37.611131]},
      {name: "МЕДСИ", coordinates: [55.717363, 37.592198]},
    ],
    enabled: true
  },

  // магазины
  shop: {
    icon: "/images/map-icons/sity-infrostructure/shops.svg",
    sizes: [24, 42],
    offset: [-12, -42],
    items: [
      {name: "«Азбука вкуса»",coordinates: [55.718289, 37.596446]},
      {name: "«Азбука вкуса»", coordinates: [55.718247, 37.622146]},
      {name: "«Азбука вкуса»", coordinates: [55.708021, 37.622131]},
      {name: "«Азбука вкуса»", coordinates: [55.707196, 37.578046]},
      {name: "«Перекрёсток»", coordinates: [55.723493, 37.610075]},
      {name: "«Перекрёсток»", coordinates: [55.710154, 37.620441]},
      {name: "«Перекрёсток»", coordinates: [55.714848, 37.621995]},
      {name: "Сахалинский икорный дом", coordinates: [55.722789, 37.621627]},
      {name: "Даниловский рынок", coordinates: [55.712363, 37.620578]},
    ],
    enabled: true
  },

  // кафе
  food: {
    icon: "/images/map-icons/sity-infrostructure/food.svg",
    sizes: [24, 42],
    offset: [-12, -42],
    items: [
      {name: "Starlite Diner", coordinates: [55.729321, 37.616249]},
      {name: "Blanc de Blanc Cafe", coordinates: [55.721883, 37.621579]},
      {name: "Ресторан «Де Марко»", coordinates: [55.722471, 37.602843]},
      {name: "Паб \"Джон Донн\"", coordinates: [55.727498, 37.608894]},
    ],
    enabled: true
  },
  fitnes: {
    icon: "",
    sizes: [],
    items: [
      {name: "Republika", coordinates: [55.730754, 37.628210]},
      {name: "EMS Fit-n-Go", coordinates: [55.711800, 37.656722]},
      {name: "CityFitness", coordinates: [55.728188, 37.616076]},
      {name: "World Gym", coordinates: [55.711224, 37.632752]},
      {name: "Зебра", coordinates: [55.708443, 37.652294]},

    ],
    enabled: true
  },
  museum: {
    icon: "",
    sizes: [],
    items: [
      {name: "Третьяковская галерея (новая)", coordinates: [55.734716, 37.605981]},
      {name: "Музей современного искусства «Гараж»", coordinates: [55.727870, 37.601687]},
      {name: "Арт–центр «Exposed»", coordinates: [55.720803, 37.610237]},
      {name: "Музей С. Есенина", coordinates: [55.726619, 37.630732]},
    ],
    enabled: true
  },
  mfc: {
    icon: "",
    sizes: [],
    items: [
      {name: "Мои документы", coordinates: [55.712867, 37.613019]},
      {name: "Мои документы", coordinates: [55.707371, 37.600516]},
      {name: "Мои документы", coordinates: [55.733665, 37.609979]},
    ],
    enabled: true
  }
};

module.exports = {places, pictures, sityInfroStruct};
